import React, { useCallback, useState, Fragment, useMemo } from 'react';
import styles from './AvailableRewards.module.scss';
import { useAppStateContext } from 'stateProviders/useAppStateContext';
import AvailableRewardsList from './AvailableRewardsList/AvailableRewardsList';
import AddRewardToOrderModal from './AddRewardToOrderModal/AddRewardToOrderModal';
import { useQueryUrlParamsDispatch, useQueryUrlParams } from 'hooks';
import { TextButton } from 'components/Button/Button';

const AvailableRewards = () => {
  const { restaurantId: queryRestaurantId } = useQueryUrlParams();
  const {
    restaurant,
    favoriteOloRestaurant: { data: dataFavoriteOloRestaurant },
    user: { data: userData },
  } = useAppStateContext();
  const appStateRestaurantId = restaurant && restaurant.data ? restaurant.data.id : null;
  const restaurantId =
    queryRestaurantId || appStateRestaurantId || (dataFavoriteOloRestaurant ? dataFavoriteOloRestaurant.id : null);
  const setRedirect = useQueryUrlParamsDispatch();
  const [modalState, setModalState] = useState(false);
  const [rewardToAdd, setRewardToAdd] = useState(null);

  const rewardBalances = useMemo(() => userData.accountInfo.rewardBalances, [userData]);

  const goToMenu = useCallback(() => {
    setModalState(false);
    if (restaurantId) {
      setRedirect(null, `/${restaurantId}/menu`, false, ['restaurantId']);
    } else {
      setRedirect(null, `/`, false);
    }
  }, [setRedirect, restaurantId]);

  const goToSelectRestaurant = useCallback(() => {
    setRedirect({ location: true }, '/', false);
  }, [setRedirect]);

  const rewardDetailsClick = (reward) => {
    setRewardToAdd(reward);
    setModalState(true);
  };

  return (
    <div className={styles.available_rewards_container}>
      {!restaurantId && (
        <div className={styles.select_restaurant}>
          Please
          <TextButton className={styles.select_restaurant_link} onClick={goToSelectRestaurant}>
            select restaurant
          </TextButton>
          to see available rewards
        </div>
      )}
      {rewardBalances &&
        (rewardBalances.length > 0 ? (
          <Fragment>
            <AvailableRewardsList data={rewardBalances} handleDetailsReward={rewardDetailsClick} />
            <p className={styles.rewards_helper}>
              *Member Monday offer subject to change. Must be enrolled in 99 REWARDS. Cannot be used with other REWARDS,
              coupons or promotions including “Kids Eat Free”. Tax and gratuity not included. Offer available for Cheese
              Burgers with Cheddar, Swiss or American Cheese. No substitutions. Toppings are an additional charge.
            </p>
            <div className={styles.divider} />
          </Fragment>
        ) : (
          <div className={styles.no_data}>No available rewards</div>
        ))}

      <AddRewardToOrderModal
        reward={rewardToAdd}
        isModalOpen={modalState}
        handleIsModalOpen={setModalState}
        handleModalSubmit={goToMenu}
      />
    </div>
  );
};

export default React.memo(AvailableRewards);
